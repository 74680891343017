@font-face {
  font-family: 'Encordats';
  src: url('../resources/static/font/Montserrat-ExtraLight.eot');
  src: url('../resources/static/font/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../resources/static/font/Montserrat-ExtraLight.woff2') format('woff2'),
  url('../resources/static/font/Montserrat-ExtraLight.woff') format('woff'),
  url('../resources/static/font/Montserrat-ExtraLight.ttf') format('truetype'),
  url('../resources/static/font/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encordats';
  src: url('../resources/static/font/Montserrat-Regular.eot');
  src: url('../resources/static/font/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../resources/static/font/Montserrat-Regular.woff2') format('woff2'),
  url('../resources/static/font/Montserrat-Regular.woff') format('woff'),
  url('../resources/static/font/Montserrat-Regular.ttf') format('truetype'),
  url('../resources/static/font/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encordats';
  src: url('../resources/static/font/Montserrat-Bold.eot');
  src: url('../resources/static/font/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('../resources/static/font/Montserrat-Bold.woff2') format('woff2'),
  url('../resources/static/font/Montserrat-Bold.woff') format('woff'),
  url('../resources/static/font/Montserrat-Bold.ttf') format('truetype'),
  url('../resources/static/font/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encordats';
  src: url('../resources/static/font/Now-Bold.eot');
  src: url('../resources/static/font/Now-Bold.eot?#iefix') format('embedded-opentype'),
  url('../resources/static/font/Now-Bold.woff2') format('woff2'),
  url('../resources/static/font/Now-Bold.woff') format('woff'),
  url('../resources/static/font/Now-Bold.ttf') format('truetype'),
  url('../resources/static/font/Now-Bold.svg#Now-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Encordats';
  src: url('../resources/static/font/Now-Black.eot');
  src: url('../resources/static/font/Now-Black.eot?#iefix') format('embedded-opentype'),
  url('../resources/static/font/Now-Black.woff2') format('woff2'),
  url('../resources/static/font/Now-Black.woff') format('woff'),
  url('../resources/static/font/Now-Black.ttf') format('truetype'),
  url('../resources/static/font/Now-Black.svg#Now-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

$font-family-sans-serif: "Encordats", Arial, "Helvetica Neue", sans-serif;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

.smaller {
    font-size: smaller;
}

.x-small {
  font-size: x-small;
}