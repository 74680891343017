@import "../../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../color";

.profile-picture-input {

  .profile-picture {
    height: 75px;
    width: 75px;
  }

  .modal {
    display: block;

    .modal-body {
      height: 420px;
      padding: 0;
    }
  }

  .overlay {
    background-color: $dark-transparent;
    inset: 0;
    opacity: 1;
    overflow: hidden;
    position: fixed;
    z-index: 99;
  }
}