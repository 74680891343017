@import "../color";
@import "../custom";

.avatar-large {
  height: 120px;
  width: 120px;
  border-radius: $border-radius-lg;
}

.avatar-icon {
  background-position: center center;
  background-size: cover;
  border-color: $white;
  border-radius: $border-radius;
  border-style: solid;
  border-width: $input-border-width;
  height: 56px;
  width: 56px;

  &:hover {
    border-color: $jugendstil-purple;
    box-shadow: 0 0 0 0.25rem transparentize($jugendstil-purple, 0.5);
    cursor: pointer;
  }

  &.active {
    border-color: $jugendstil-purple;
    box-shadow: 0 0 0 0.25rem transparentize($jugendstil-purple, 0.5);
  }
}