@import "../../node_modules/bootstrap/scss/bootstrap";

.club-logo {
  height: 52px;
  left: 10px;
  position: relative;

  &.club-logo-rectangle {
    display: none;
    width: 180px;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &.club-logo-square {
    display: block;
    width: 52px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
