@import "../color";

.language-switch {

  &:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;


    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}