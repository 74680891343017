@import "../../styles/color";
@import "../../styles/input";

$form-select-box-shadow: none;
$form-select-disabled-bg: $input-bg;
$form-select-disabled-border-color: $light-grey;
$form-select-disabled-color: $dark-grey;
$form-select-focus-box-shadow: none;

.form-select {
  &:disabled, &[readonly] {
    cursor: not-allowed !important;
  }
}
