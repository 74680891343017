@import "../../styles/color";
@import "../../styles/custom";

table.table-ranking-country {
  thead {
    tr {
      th {
        font-weight: 400;
      }
    }
  }
}