body.landing-page {
  header {
    .logo-link {
      svg {
        height: 60px;
        width: 60px;
      }
    }
  }

  .background-image {
    background-image: url('../resources/static/image/tennis-court.jpg');
    background-position: center center;
    background-size: cover;
  }

  footer {
    svg {
      height: 14px;
      margin-right: 0.5rem;
      position: relative;
      top: -2px;
      width: 14px;
    }
  }
}
