@import "../color";

.move-up {
  display: inline-block;
  width: 60px;

  svg {
    display: inline-block;

    path {
      stroke: $boring-green;
    }
  }

  .move-text {
    color: $boring-green;
  }
}

.move-down {
  display: inline-block;
  width: 60px;

  svg {
    display: inline-block;

    path {
      stroke: $pearl-black;
    }
  }

  .move-text {
    color: $pearl-black;
  }
}

.move-none {
  display: inline-block;
  width: 60px;

  svg {
    display: inline-block;

    path {
      stroke: $dark-grey;
    }
  }

  .move-text {
    color: $dark-grey;
  }
}