@import "../../styles/color";

body.fullscreen {
  header {
    margin: 1rem 0;
    width: 100%;

    .logo-link {
      svg {
        height: 60px;
        width: 60px;
      }
    }
  }

  .background-image {
    background-image: url('../resources/static/image/tennis-player.jpg');
    background-position: center center;
    background-size: cover;
  }

  main {
    width: 100%;
  }

  footer {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 1rem 0;
    width: 100%;

    svg {
      height: 14px;
      margin-right: 0.5rem;
      width: 14px;
    }
  }

  main {
    width: 100%;
  }

  .vertical-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */
  }
}
