@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../styles/color";
@import "../../styles/custom";

body.layout-landing-page-hero-image {
  background-color: $white;

  .brand {
    svg {
      height: 60px;
      width: 60px;

      @include media-breakpoint-up(sm) {
        height: 90px;
        width: 90px;
      }
    }
  }

  .nav-link {
    color: inherit;
    font-weight: 500;
    text-transform: uppercase;

    &.btn-nav-link {
      border-radius: $border-radius-sm;
      border-style: solid;
      border-width: 1px;
      white-space: nowrap;

      @include media-breakpoint-up(lg) {
        padding-left: 2.25rem;
        padding-right: 2.25rem;
      }
    }
  }

  footer {
    margin-top: 6rem;
  }
}
