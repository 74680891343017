@import "../../styles/color";

table.table-match {
  tbody {
    tr {
      td {
        .match-profile-picture {
          height: 30px;
          width: 30px;

          &.default-avatar {
            svg {
              height: 30px;
              width: 30px;

              path {
                fill: $black-transparent;
              }
            }
          }
        }

        .match-player-url {
          color: inherit;
          text-decoration: none;
        }

        .match-winner-check {
          svg path {
            fill: $jugendstil-purple;
          }
        }

        &:last-of-type {
          width: 100px;
        }
      }
    }
  }
}