@import "../../node_modules/bootstrap/scss/bootstrap";

table.table-match-up {
  tbody {
    tr {
      td {
        &:last-of-type {
          width: 1%;
          white-space: nowrap;

          a {
            max-width: 200px;

            @include media-breakpoint-up(sm) {
              min-width: 150px;
            }
          }
        }

        .match-up-profile-picture {
          height: 30px;
          width: 30px;

          &.default-avatar {
            svg {
              height: 30px;
              width: 30px;

              path {
                fill: $black-transparent;
              }
            }
          }
        }
      }
    }
  }
}