@import "../../../styles/color";
@import "../../../styles/custom";

#header-default {

  .nav-link {
    font-size: small;
  }

  .brand {
    svg {
      &.logo {
        height: 52px;
        width: 52px;

        path {
          fill: $pearl-black;
        }
      }

      &.ukraine-ribbon {
        height: 52px;
        left: 7px;
        position: relative;
        width: 34px;
      }
    }
  }

  #user-menu {
    #user-menu-link {
      margin: 0 !important;
      padding: 0 !important;

      img {
        border-radius: $border-radius-sm !important;;
        border-width: 0 !important;
        height: 39px !important;
        padding: 0 !important;
        width: 39px !important;
      }
    }
  }
}