@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../styles/color";

body#player {

  .profile-header {
    height: 180px;

    &.has-birthday {
      background-color: $white;
      background-image: url('../resources/static/image/happy-birthday-background.jpg');
      background-position: 50%;
      background-size: cover;
    }

    @include media-breakpoint-up(sm) {
      height: 200px;
    }

    .profile-header-left {
      margin-bottom: -15px;
      width: 130px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 10px;
        width: 180px;
      }

      .profile-picture {
        height: 120px;
        width: 120px;
        z-index: 1;

        @include media-breakpoint-up(sm) {
          height: 180px;
          width: 180px;
        }
      }
    }

    .profile-header-right {
      margin-bottom: 10px;
    }
  }
}
