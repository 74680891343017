@import "../color";
@import "../custom";

$nav-link-color: $pearl-black;
$nav-link-hover-color: $pearl-black;
$nav-tabs-border-color: $pearl-black;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-bg: $pearl-black;
$nav-tabs-link-active-bg: $pearl-black;
$nav-tabs-link-active-border-color: $pearl-black;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-hover-border-color: $pearl-black;

$navbar-padding-y: 1rem;
$navbar-padding-x: .5rem;

body {
  --bs-nav-link-padding-y: #{$nav-link-padding-y};
  --bs-nav-link-padding-x: #{$nav-link-padding-x};
}