@import "../../styles/color";

$pagination-active-bg: $pearl-black;
$pagination-active-color: $white;
$pagination-color: $pearl-black;
$pagination-focus-box-shadow: none;
$pagination-focus-color: $pearl-black;
$pagination-hover-color: $pearl-black;
$pagination-disabled-bg: $white;

.pagination-jugendstil {

  .page-item {
    &.active {
      .page-link {
        background-color: $jugendstil-purple;
        border-color: $jugendstil-purple;
        color: $white;
        z-index: 3;
      }
    }
  }
}