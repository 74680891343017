@import "../../styles/color";
@import "../../styles/custom";

table.table-search-result {
  thead {
    tr {
      th {
        font-weight: 400;

        &.search-result-image {
          width: 90px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        .search-result-image {
          border-radius: $border-radius-sm;
          height: 65px;
          width: 65px;

          &.default-image {
            border-color: $light-grey;
            border-style: solid;
            border-width: 1px;
          }
        }

        .search-result-url {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}