@import "../../../styles/color";
@import "../../../styles/custom";

#header-transparent {
  #user-menu {
    #user-menu-link {
      margin: 0 !important;
      padding: 0 !important;

      img {
        border-color: $white !important;
        border-radius: $border-radius-sm !important;;
        border-width: 1px !important;
        height: 42px !important;
        padding: 0 !important;
        width: 42px !important;
      }
    }
  }
}