@import "../../styles/color";
@import "../../styles/input";

.input-group {
  background-color: $input-bg;
  border-color: $input-focus-border-color;
  border-width: $input-border-width;

  &:hover {
    background-color: $input-bg;
    border-color: $input-focus-border-color;
    border-width: $input-border-width;
  }

  label {
    i {
      align-items: center;
      color: $pearl-black;
      display: flex;
      font-size: 1rem;
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
    }
  }

  .invalid-feedback {
    background-color: $white;
    margin: 0;
    padding: 0.375rem 0.75rem;
  }
}