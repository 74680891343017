@import "color";
@import "font";
@import "theme";

$body-bg: $white;
$body-color: map-get($theme-colors, "dark");

// Alert
$alert-border-radius: 4px;

// Border radius
$border-radius: .65rem;
$border-radius-sm: .25rem;
$border-radius-lg: 1rem;

// Navigation
$nav-link-padding-x: 1rem;
$nav-link-padding-y: .5rem;
$navbar-toggler-focus-width: 0;

// Button
$btn-box-shadow: none !important;
$btn-focus-box-shadow: none !important;
$btn-active-box-shadow: none !important;

// Popover
$popover-border-radius: $border-radius-sm;

// Tooltip
$tooltip-bg: $pearl-black;
$tooltip-border-radius: $border-radius-sm;
$tooltip-color: $white;

[data-bs-toggle="tooltip"] {
  &:hover {
    cursor: help;
  }
}
