@import "../../styles/color";

#user-menu {
  .dropdown-item, .dropdown-header {
    text-transform: none;
  }

  li {
    .headline {
      background-color: $pearl-black;
      color: $white;
      display: block;
      font-size: 8px;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      padding: 0.25rem 1rem;
      text-transform: uppercase;
    }
  }
}