//@import "../../node_modules/@fortawesome/fontawesome-free/scss/functions";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/variables";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/mixins";
//@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../styles/color";
@import "../../styles/custom";

body#homepage {
  .background-image {
    background-image: url('../resources/static/image/tennis-player-01.jpg');
    background-position: 50%;
    background-size: cover;
    min-height: 725px;

    p {
      max-width: 880px;
      text-shadow: $black 1px 1px 1px;
    }
  }

  section {
    &.marketing {
      .h1 {
        font-size: xxx-large;
        font-weight: 900;
      }

      .border-inherit {
        border-color: inherit;
        border-style: solid;
        border-width: 1px;
      }

      .box-shadow-macaroni-and-cheese {
        box-shadow: $macaroni-and-cheese 20px 20px 1px;
      }

      &#about-us {
        padding-top: 6rem;
        padding-bottom: 6rem;

        @include media-breakpoint-up(lg) {
          padding-top: 12rem;
          padding-bottom: 12rem;
        }

        img {
          height: auto;
          max-height: 400px;
          max-width: 100%;
          padding: 1rem;
          width: auto;
        }
      }

      &#clubs {
        overflow-x: clip;

        .spacer {
          @include media-breakpoint-up(sm) {
            height: 5rem;
          }

          @include media-breakpoint-up(lg) {
            height: 7rem;
          }
        }

        .content {
          padding-bottom: 5rem;
          padding-top: 5rem;
        }

        img {
          left: -330px;
          position: relative;
          top: -160px;
          transform: scaleX(-1);
          width: 650px;

          @include media-breakpoint-up(md) {
            left: -180px;
            top: -150px;
          }

          @include media-breakpoint-up(lg) {
            left: -80px;
            top: -200px;
            width: 720px;
          }
        }
      }

      &#partner {
        margin-bottom: 6rem;
        margin-top: 6rem;
        padding-bottom: 5rem;
        padding-top: 5rem;

        img {
          filter: grayscale(1);
          width: 250px;
        }
      }

      &#ranking {
        padding-top: 6rem;
        padding-bottom: 6rem;

        @include media-breakpoint-up(lg) {
          padding-top: 12rem;
          padding-bottom: 12rem;
        }
      }

      &#quote {
        margin-bottom: 6rem;
        margin-top: 6rem;
        padding-bottom: 5rem;
        padding-top: 5rem;
        text-align: center;

        p {
          font-size: 1.5rem;
          font-weight: 500;
        }

        svg {
          fill: $white;
          height: 40px;
          width: 40px;
        }

        .blockquote-footer {
          color: $white;
        }
      }

      &#matches {
        margin-bottom: 6rem;
        margin-top: 6rem;
        padding-bottom: 5rem;
        padding-top: 5rem;
      }
    }
  }
}