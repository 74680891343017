table.table-user-registration {

  [data-bs-toggle='collapse'] {
    &[aria-expanded='true'] {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  thead {
    tr {
      th {
        &:first-of-type {
          padding-left: 1.5rem;
        }

        &:last-of-type {
          padding-left: 1.5rem;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-of-type {
          padding-left: 1.5rem;
        }

        &:last-of-type {
          padding-left: 1.5rem;
        }
      }
    }
  }
}