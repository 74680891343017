@import "../color";

.autocomplete {

  .form-control:focus {
    color: inherit;
    background-color: $off-white;
    border-color: $off-white;
    outline: 0;
    box-shadow: none;
  }

  ul {
    bottom: 3px;
    position: relative;
    z-index: 1001;

    .list-group-item {
      color: inherit;
      background-color: $off-white;
      border-color: $off-white;

      &:hover {
        cursor: pointer;
      }

      &:hover, &.active {
        background-color: $white;
        border-color: $white;
      }
    }

    li:first-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}