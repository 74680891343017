@import "color";

$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

.btn-confetti {
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;

  &:active {
    transform: scale(0.9);
  }

  svg {
    height: 26px;
    margin-left: 0.5rem;
    position: relative;
    top: -2px;
    width: auto;
  }
}

.btn-primary {
  color: $white !important;

  &:hover {
    color: $white !important;
  }
}

.btn-outline-primary {
  &:hover {
    color: $white !important;
  }
}

.btn-check:active + .btn-outline-secondary,
.btn-check:hover + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-check:focus + .btn-outline-secondary {
  background-color: $white !important;
  border-color: $white;
  box-shadow: none !important;
  color: $dark-grey !important;
  outline: none !important;

  i {
    &.fa-circle {
      font-weight: 900;
    }
  }
}

label {

  &.btn-outline-secondary,
  &.btn-outline-secondary.active,
  &.btn-outline-secondary.focus,
  &.btn-outline-secondary.hover,
  &.btn-outline-secondary:active,
  &.btn-outline-secondary:focus,
  &.btn-outline-secondary:hover,
  {
    color: $dark-grey !important;
    background-color: $white;
    border-color: $white !important;

    i {

      &.fa-circle {
        color: $dark-grey !important;
      }
    }
  }
}
