@import "../../node_modules/bootstrap/scss/bootstrap";

.thumbnail-grid {
  align-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  .thumbnail-item {
    width: 22%;
    margin-bottom: 1em;

    @include media-breakpoint-up(sm) {
      margin-bottom: 1.5em;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 2em;
    }

    .thumbnail-img {
      display: block;
      max-width: 100%;
    }
  }
}

