@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../color";

body#match {

  .profile-picture-wrapper {
    position: relative;
  }

  .profile-picture {
    height: 120px;
    width: 120px;

    &.profile-picture-small {
      height: 30px;
      width: 30px;

      &.default-avatar {
        svg {
          height: 30px;
          width: 30px;

          path {
            fill: $black-transparent;
          }
        }
      }
    }

    &.default-avatar {
      svg {
        height: 120px;
        width: 120px;

        path {
          fill: $black-transparent;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      height: 180px;
      width: 180px;

      &.default-avatar {
        svg {
          height: 180px;
          width: 180px;

          path {
            fill: $black-transparent;
          }
        }
      }
    }
  }

  .match-winner-check {
    svg path {
      fill: $jugendstil-purple;
    }
  }
}
