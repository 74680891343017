.pricing-card {
  background-clip: border-box;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  text-align: center;
  word-wrap: break-word;

  .pricing-card-header {
    font-size: 1.5rem;
    font-weight: 900;
    padding: 4rem 1rem 1rem 1rem;
    text-transform: uppercase;
  }

  .pricing-card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;

    .pricing-card-title {
      font-size: 2.5rem;
      font-weight: 700;
      letter-spacing: -0.15rem;
      margin: 0 0 1rem 0;
    }

    .pricing-card-subtitle {
    }
  }

  .pricing-card-footer {
    padding: 1rem 1rem 3rem 1rem;
  }
}