@import "color";

$input-bg: $light-grey;
$input-border-color: $light-grey;
$input-border-radius: 4px;
$input-border-width: 1.5px;
$input-disabled-bg: $input-bg;
$input-disabled-border-color: $light-grey;
$input-focus-border-color: $pearl-black;
$input-focus-box-shadow: none;
$input-placeholder-color: $dark-transparent;
$input-plaintext-color: $pearl-black;
