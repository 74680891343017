@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../color";

body#head-to-head {
  .chart {
    --val: 0;

    svg {
      transform: rotate(-90deg);
    }

    .percent {
      stroke-dasharray: 100;
      stroke-dashoffset: calc(100 - var(--val));
    }

    .fifty {
      --val: 50;
    }

    .sixty {
      --val: 60;
    }

    .ninety {
      --val: 90;
    }
  }

  .profile-picture {
    height: 120px;
    width: 120px;

    &.profile-picture-small {
      height: 60px;
      width: 60px;

      &.default-avatar {
        svg {
          height: 60px;
          width: 60px;
        }
      }
    }

    &.default-avatar {
      svg {
        height: 120px;
        width: 120px;

        path {
          fill: $black-transparent;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      height: 180px;
      width: 180px;

      &.default-avatar {
        svg {
          height: 180px;
          width: 180px;
        }
      }
    }
  }

  [data-controller="head-to-head"] {
    display: inline-block;
    position: relative;
    width: 100%;

    [data-head-to-head-target="input"] {
      color: inherit;
      background-color: $white;
      border-color: $white;
      outline: 0;
      box-shadow: none;
    }

    [data-head-to-head-target="results"] {
      font-size: smaller;
      margin-top: -3px;
      position: absolute;
      width: 100%;

      .list-group-item {
        color: inherit;
        background-color: $off-white;
        border-color: $off-white;

        &:hover {
          cursor: pointer;
        }

        &:hover, &.active {
          background-color: $white;
          border-color: $white;
        }
      }

      li:first-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}