.notification-bar {
  font-size: 12px;

  &.fade-in {
    animation-duration: 0.25s;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  svg {
    height: 20px;
    margin-right: 3px;
    width: 20px;
  }
}
