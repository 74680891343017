@import "../color";
@import "../custom";

ul.match-outcome {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  :not(:first-child) {
    margin-left: 2px;
  }

  li {
    display: block;
    font-weight: bold;
    height: 25px;
    text-align: center;
    width: 25px;

    a {
      color: inherit;
      display: block;
      margin-top: 2px;
      position: relative;
      text-decoration: none;
    }

    &.match-outcome-win {
      color: $white;
      background-color: $shamrock-green;
    }

    &.match-outcome-lose {
      color: $white;
      background-color: $indian-red;
    }

    &.match-outcome-draw {
      color: $pearl-black;
      background-color: $light-grey;
    }
  }
}