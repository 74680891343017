@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../styles/color";
@import "../../styles/custom";

body.layout-account,
body.layout-admin,
body.layout-player,
body.layout-standard
{
  background-color: $white;

  .background-image {
    background-image: url('../resources/static/image/tennis-player-01.jpg');
    background-position: 50%;
    background-size: cover;
    min-height: 725px;

    p {
      max-width: 880px;
      text-shadow: $black 1px 1px 1px;
    }
  }

  .brand {
    svg {
      height: 60px;
      width: 60px;

      @include media-breakpoint-up(sm) {
        height: 90px;
        width: 90px;
      }
    }
  }

  .nav-link {
    color: inherit;
    font-weight: 500;
    text-transform: uppercase;

    &.btn-nav-link {
      border-radius: $border-radius-sm;
      border-style: solid;
      border-width: 1px;
      white-space: nowrap;

      @include media-breakpoint-up(lg) {
        padding-left: 2.25rem;
        padding-right: 2.25rem;
      }
    }
  }

  section {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  footer {
    margin-top: 6rem;
  }
}
