@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../color";

.profile-picture {
  height: 120px;
  width: 120px;

  &.profile-picture-30 {
    height: 30px;
    width: 30px;

    &.default-avatar {
      svg {
        height: 30px;
        width: 30px;

        path {
          fill: $black-transparent;
        }
      }
    }
  }

  &.profile-picture-60 {
    height: 60px;
    width: 60px;

    &.default-avatar {
      svg {
        height: 60px;
        width: 60px;

        path {
          fill: $black-transparent;
        }
      }
    }
  }

  &.default-avatar {
    svg {
      height: 120px;
      width: 120px;

      path {
        fill: $black-transparent;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    height: 180px;
    width: 180px;

    &.default-avatar {
      svg {
        height: 180px;
        width: 180px;

        path {
          fill: $black-transparent;
        }
      }
    }
  }
}
