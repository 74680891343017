@import "../color";

header {
  .digital-innovation-partner {
    background-color: $white;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 0;
    text-transform: uppercase;

    img {
      height: 12px;
      position: relative;
      top: 0.5px;
    }

    span {
      letter-spacing: -0.5px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  a.logo {

    svg {
      height: 40px;
      width: 40px;
    }

    &:hover {
      color: $custard !important;

      path {
        fill: $custard !important;;
      }
    }
  }
}

.hero-wrapper {
  .hero-content {
    bottom: calc(-100vh + 320px);
    margin-bottom: 25px;
    position: relative;

    h1 {
      background-color: $white;
      display: inline-block;
      margin: 0;
      padding: 1rem 3rem 0 1.2rem;
    }
    .hero-text {
      background-color: $white;
      display: inline-flex !important;
      padding: 1rem 3rem 1.2rem 1.2rem;
      position: relative;
      top: -0.55rem;
    }
  }

  .hero-background-image {
    background-position: center center;
    background-size: cover;
    height: calc(100vh - 11px);
    width: auto;
  }

  .rolex {
    border-top: 1px solid transparent;
    height: 100px;
    width: 100%;
  }

  .cover {
    display: block;
    height: 100px;
    margin-top: -100px;
    position: relative;
    top: 101px;
    width: 100%;
    z-index: 1;
  }

  .spacer {
    background-color: $shamrock-green;
    height: 10px;
    margin-top: 1px;
  }
}