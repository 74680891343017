
body#club {
  .table-tournament-playing-category-table {
    tr:first-of-type {
      td {
        border-top: none !important;
      }
    }
  }
}
